import React from "react";
import Featurestr from "../../subcomponents/Features";
export default function Features({ plansData }) {
  return (
    <div className="btmconblur">
      <h1>{plansData?.language?.pay_features_heading}</h1>
      <div className="planava">
        <table>
          <thead>
            <tr>
              <th>{plansData?.language?.pay_features?.toUpperCase()}</th>
              <th className="CTR">
                {plansData?.language?.pay_features_type_1?.toUpperCase()}
              </th>
              {/* <th className="CTR">
                {plansData?.language?.pay_features_type_2?.toUpperCase()}
              </th> */}
              <th className="CTR">
                {plansData?.language?.pay_features_type_3?.toUpperCase()}
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {
                        plansData?.data?.features?.map((x, i) => {
                            return (
                                <Featurestr key={i} title={x.featureName} img1={x.userType.adsFree ? "tickimg.svg" : "crpssimg.svg"} img2={x.userType.free ? "tickimg.svg" : "crpssimg.svg"} img3={x.userType.gold ? "tickimg.svg" : "crpssimg.svg"} />
                            )
                        })
                    } */}
            <Featurestr language={plansData?.language} />
          </tbody>
        </table>
      </div>
      <div className="gemg CTR">
        {plansData?.language?.pay_pickplan_title_text}
      </div>
      <div className="takeexp">
        {plansData?.language?.pay_pickplan_subtitle_text}
      </div>
      <div className="CTR" id="scrolltop">
        <a href="#inner-mid-area" className="vodablueprimary_btn">
          {plansData?.language?.pay_pickplan_btn_text}
        </a>
      </div>
    </div>
  );
}
